












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import TheProfile from '@/components/Common/TheProfile.vue'
import TheNetworkProfile from '@/components/Common/TheNetworkProfile.vue'
import TheCreditUsage from '@/components/Common/TheCreditUsage.vue'
import TheUsageAlert from '@/components/Common/TheUsageAlert.vue'
import TheAppSwitch from '@/components/Common/TheAppSwitch.vue'
import { SUBSCRIPTION_STATUS } from '@/constants/subscription'
import * as Sentry from '@sentry/vue'
import LoginButton from '@/components/Common/LoginButton.vue'
import SignupButton from '@/components/Common/SignupButton.vue'
import adoriLogo from '@/assets/adori-without-text.svg'

@Component({
  components: {
    TheProfile,
    TheNetworkProfile,
    TheCreditUsage,
    LoginButton,
    SignupButton,
    TheUsageAlert,
    TheAppSwitch,
  },
})
export default class TheHeader extends Vue {
  @Getter currentNetworkSubscriptions!: any
  @Getter networkId!: any
  @Getter networkType!: any
  @Getter isYoutube!: Boolean
  @Getter isStudio!: Boolean
  @Getter isVideo!: Boolean
  @Getter selectedProduct!: string

  get isUsageAllowed() {
    return ['YOUTUBE_METERED', 'YOUTUBE_PRO'].includes(this.networkType)
  }
  get isNewUsage() {
    return ['VIDEO_FREE', 'VIDEO_PRO', 'VIDEO_BASIC'].includes(this.networkType)
  }

  isPath(path: string) {
    return this.$router.currentRoute.fullPath === path
  }

  isPathRegex(regex: RegExp) {
    return regex.test(this.$router.currentRoute.fullPath)
  }

  get logoSrc() {
    return adoriLogo
  }

  get permissionError() {
    if (this.isPath('/permission-error')) {
      return true
    }
    return false
  }

  get isReferAllowed() {
    //@ts-ignore
    return this.networkType === 'YOUTUBE_METERED' && window.growsurf
  }

  get hasSubscriptions() {
    if (this.isPath('/plans')) {
      if (!this.$permissions.isManageNetworkBillingAllowed()) return true
      const subs: any = this.currentNetworkSubscriptions
      if (subs && !subs.hasUpgraded) return true
      if (
        subs &&
        subs.subscription &&
        (subs.subscription.status === SUBSCRIPTION_STATUS.trial ||
          subs.subscription.status === SUBSCRIPTION_STATUS.active)
      ) {
        return true
      }
      return false
    }
  }

  handleRoute() {
    this.isYoutube ? this.$router.push('/home') : this.$router.push('/overview')
  }

  gotoHelp() {
    window.open(`https://help.adorilabs.com`, '_blank')
  }

  bookDemo() {
    window.open(`https://www.adoriai.com/contact`, '_blank')
  }

  feedback() {
    Sentry.captureMessage('FEEDBACK')
  }

  openGrowSurf() {
    //@ts-ignore
    growsurf.open()
  }
}
