





















import { useGetBillingUsage } from '@/hooks/billing'
import { Getter } from 'vuex-class'
import { SetupContext } from '@vue/composition-api'
import { Component, Vue } from 'vue-property-decorator'
import get from 'lodash.get'

@Component({
  setup(_, { root }: SetupContext) {
    const { data: usageData, isLoading: isUsageLoading } = useGetBillingUsage({
      enabled: true,
    })
    return {
      usageData,
      isUsageLoading,
    }
  },
})
export default class TheUsageAlert extends Vue {
  @Getter networkType!: any
  usageData!: any
  isUsageLoading!: boolean

  usageType = 'generated_videos'

  created() {
    if (!!localStorage.getItem('USAGE_TYPE')) {
      this.usageType = localStorage.getItem('USAGE_TYPE') ?? 'generated_videos'
    }
  }

  get isFreeAccount() {
    return ['VIDEO_FREE'].includes(this.networkType)
  }

  get usageText() {
    switch (this.usageType) {
      case 'generated_videos':
        return this.isFreeAccount
          ? 'No of Downloads remaining: 0'
          : `No of Downloads remaining: ${
              this.usageItem.maxAllowed - this.usageItem.used <= 0 ? 0 : this.usageItem.maxAllowed - this.usageItem.used
            }`
      case 'episode_count':
        return `No of previews remaining: ${
          this.usageItem.maxAllowed - this.usageItem.used <= 0 ? 0 : this.usageItem.maxAllowed - this.usageItem.used
        }`
      case 'video_dalle':
        return `AI images remaining: ${
          this.usageItem.maxAllowed - this.usageItem.used <= 0 ? 0 : this.usageItem.maxAllowed - this.usageItem.used
        }`
      case 'transcription':
        return `Transcription mins remaining: ${
          this.usageItem.maxAllowed - this.usageItem.used <= 0 ? 0 : this.usageItem.maxAllowed - this.usageItem.used
        }`
      case 'published_minutes':
        return `Video creation mins remaining: ${
          this.usageItem.maxAllowed - this.usageItem.used <= 0 ? 0 : this.usageItem.maxAllowed - this.usageItem.used
        }`
    }
  }

  get usage() {
    return get(this.usageData, 'data', [])
  }

  get usageItem() {
    return this.usage.find((obj: any) => obj.usageType === this.usageType)
  }

  get isUpgradeRequired() {
    const videoLimit = this.usage.find((obj: any) => obj.usageType === 'generated_videos')
    const videoMinLimit = this.usage.find((obj: any) => obj.usageType === 'published_minutes')
    if (videoLimit.maxAllowed - videoLimit.used <= 0 || videoMinLimit.maxAllowed - videoMinLimit.used <= 0) return true
    return false
  }
  openModal() {
    this.$store.commit('setCurrentModal', 'plans')
  }

  popupMenuItems() {
    return [
      {
        name: 'Show Video Downloads Usage',
        icon: 'slideshow',
        disabled: false,
        onClick: () => {
          localStorage.setItem('USAGE_TYPE', 'generated_videos')
          this.usageType = 'generated_videos'
        },
      },
      {
        name: 'Show Video Preview Usage',
        icon: 'slideshow',
        disabled: false,
        onClick: () => {
          localStorage.setItem('USAGE_TYPE', 'episode_count')
          this.usageType = 'episode_count'
        },
      },
      {
        name: 'Show AI image Usage',
        icon: 'psychology',
        disabled: false,
        onClick: () => {
          localStorage.setItem('USAGE_TYPE', 'video_dalle')
          this.usageType = 'video_dalle'
        },
      },
      {
        name: 'Show Transcription Usage',
        icon: 'text_snippet',
        disabled: false,
        onClick: () => {
          localStorage.setItem('USAGE_TYPE', 'transcription')
          this.usageType = 'transcription'
        },
      },
      {
        name: 'Show Video Generation Mins',
        icon: 'hourglass_top',
        disabled: false,
        onClick: () => {
          localStorage.setItem('USAGE_TYPE', 'published_minutes')
          this.usageType = 'published_minutes'
        },
      },
    ]
  }
}
