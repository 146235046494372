










import { Component, Prop, Vue } from 'vue-property-decorator'
import { ADORI_API_BASE_URL, ADORI_SSO_BASE_URL } from '@/constants'
import { Action, Getter } from 'vuex-class'
@Component
export default class TheProfile extends Vue {
  @Prop(Boolean) hasSubscriptions!: boolean
  @Prop(Boolean) error!: boolean

  @Getter email!: string
  @Getter networkId!: any
  @Getter currentNetwork!: any
  @Getter networkType!: any
  @Getter trialPeriodEnded!: boolean
  @Getter isYoutube!: boolean

  @Action changeNetwork!: Function
  @Action resetState!: Function
  @Action resetAllStates!: Function

  get account_icon() {
    return window.innerWidth >= 600 ? 'arrow_drop_down' : ''
  }

  get avatar() {
    return `${ADORI_API_BASE_URL}/images/${this.$store.getters.avatar}`
  }
  get networks() {
    return this.$store.getters.networks
  }

  get profileMenu() {
    const profile = {
      name: 'My profile',
      icon: 'face',
      onClick: () => {
        this.$store.dispatch('showProfileEdit', this.$store.getters.profile)
      },
    }

    const accountSetting = {
      name: 'Account settings',
      icon: 'settings_applications',
      onClick: () => {
        this.$router.push('/settings')
      },
    }

    const signOut = {
      name: 'Sign out',
      icon: 'logout',
      onClick: async () => {
        this.$store.dispatch('clearSession')
        //@ts-ignore
        window.growsurf && growsurf.logout()
        window.location.href = `${window.location.origin}/login`
      },
    }
    if (!this.trialPeriodEnded && !this.error) {
      return [accountSetting, signOut]
    } else if (this.trialPeriodEnded) {
      return [profile, signOut]
    } else {
      return [signOut]
    }
  }
  get username() {
    return this.$store.getters.name
  }
}
