var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex flex-wrap justify-between bg-near-black sticky",
      staticStyle: { "z-index": "12" },
      attrs: { id: "theAdoriHeader" },
    },
    [
      _c("div", { staticClass: "w-20 w-half-l flex items-center" }, [
        _c(
          "div",
          {
            staticClass: "flex items-center pointer",
            on: { click: _vm.handleRoute },
          },
          [
            _vm.hasSubscriptions
              ? _c("img", {
                  staticClass: "db pv2 pointer mw-img",
                  attrs: { src: _vm.logoSrc },
                })
              : _c("img", {
                  staticClass: "db pv2 pointer mw-img ml2",
                  attrs: { src: _vm.logoSrc },
                }),
            _c("span", { staticClass: "mobile-hide white f6 ml2 mt1" }, [
              _vm._v(
                "ADORI " +
                  _vm._s(
                    _vm.isStudio
                      ? "STUDIO"
                      : _vm.selectedProduct === "BLOG"
                      ? "AI"
                      : "VIDEO"
                  )
              ),
            ]),
          ]
        ),
        _vm.$env.windowOrigin() !== _vm.$env.envConstProd()
          ? _c(
              "div",
              {
                staticClass: "f5 silver fw5 flex items-center mr3 mt1",
                staticStyle: { "margin-left": "6px" },
              },
              [
                _vm._v("\n      ·\n      "),
                _c(
                  "span",
                  {
                    staticClass: "silver fw5",
                    staticStyle: { "margin-left": "5px", "font-size": "13px" },
                  },
                  [_vm._v(_vm._s(_vm.$env.windowOrigin()))]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "w-80 flex justify-end items-center" },
        [
          !_vm.isFree
            ? _c(
                "div",
                {
                  staticClass:
                    "mobile-hide bg-adori-red br2 pa2 mr3 ph3 f6 white pointer",
                  on: { click: _vm.bookDemo },
                },
                [_vm._v("\n      Book a Demo\n    ")]
              )
            : _vm._e(),
          _vm.isUsageAllowed && _vm.$permissions.isManageNetworkBillingAllowed()
            ? _c("TheCreditUsage")
            : _vm._e(),
          _vm.isNewUsage ? _c("TheUsageAlert") : _vm._e(),
          _c("BaseButtonTab", {
            staticClass: "mr3",
            attrs: { onClick: _vm.gotoHelp, text: "FAQs", id: "feedback" },
          }),
          !_vm.networkId
            ? _c(
                "div",
                { staticClass: "flex" },
                [_c("LoginButton", { staticClass: "mr3" }), _c("SignupButton")],
                1
              )
            : _vm._e(),
          _c("TheNetworkProfile", { staticClass: "mr2" }),
          _vm.networkId
            ? _c("TheProfile", {
                attrs: {
                  "has-subscriptions": _vm.hasSubscriptions,
                  error: _vm.permissionError,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }